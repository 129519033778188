import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthInterface } from '../interfaces/interfaces';
import { SettingsProvider } from './settings';
import { BehaviorSubject, Observable, map, of } from 'rxjs';
import { HttpClientProvider } from './http-client';
import { LoadingService } from './loading.service';
import { AlertService } from './alert.service';
import { NotificationService } from './notification.service';
import { environment } from '../../environments/environment';
import { StorageService } from '../storage/storage.service';

export type UserType = AuthInterface | null;

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	env = environment;

	private _user = new BehaviorSubject<UserType>(null);
	user$ = this._user.asObservable();

	get currentUserValue(): UserType {
		return this._user.value;
	}
	set currentUserValue(user: UserType) {
		this._user.next(user);
	}

	headers:any = null;
	
	constructor(
		private httpClient: HttpClient,
		private http: HttpClientProvider,
		private loading: LoadingService,
		private router: Router,
		private storage: StorageService,
	) {
	}

	getAuth():UserType {
		return this.storage.getItem<UserType>('auth') ?? null;
	}

	getUser(): UserType {
		return this.currentUserValue ?? null;
	}

	getToken(){
		let auth = this.storage.getItem<UserType>('auth');
		return auth ? auth.access_token : null;
	}

	logout(type?:any) {
		if(this.getToken()) {
			this.loading.loadingShow();
			this.http.postRequest(SettingsProvider.getUrl('logout'), {}).subscribe(() => {
				this.loading.dismiss();
				this.clearData();
			}, error => {
				this.loading.dismiss();
				this.clearData();
			});
		} else {
			this.clearData();
		}
		
		if(type&&type == 'expired') {
			// alert('Tu sesión a expirado.');
		}
	}

	clearData() {
		this.currentUserValue = null;
		this.storage.clear();
		this.router.navigateByUrl('/auth');
	}

	clearDataWithoutRedirect() {
		this.currentUserValue = null;
		this.storage.clear();
	}

	successfullOAuthAuthorization(token:any, expires_in?:number, customer?: any) {
		const auth:AuthInterface = { access_token: token, expires_in: expires_in, customer };
		this.storage.setItem('auth', auth);
	}

	getUserByToken(): Observable<UserType> {
		const auth = this.getAuth();
		if (!auth || !auth.access_token) {
			return of(null);
		}

		// * Si se quiere retornar según storage al user
		return of(this.userData(auth));


		// * Si se quiere retornar según api (post login / cada que la app inicia por primera vez)
		// return this._getUserByToken(auth.access_token).pipe(
		// 	map((data: any) => {
		// 		console.log(data);
		// 		let itemData:AuthInterface = {
		// 			...auth,
		// 			customer: data,
		// 		}
		// 		this.updateUserStorage(itemData.customer);
		// 		return this.userData(itemData);
		// 	})
		// );
	}

	_getUserByToken(token: string): Observable<AuthInterface> {
        const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });
		return this.httpClient.post<AuthInterface>(SettingsProvider.getAccount(), {}, {
            headers: httpHeaders,
        });
	}

	userData(user: UserType) {
		if (user) {
		  	this.currentUserValue = user;
		} else {
		  	this.logout();
		}
		return user;
	}

	updateUserStorage(customer: AuthInterface['customer']) {
		let auth = this.storage.getItem<UserType>('auth');
		if(auth) {
			const authNew:AuthInterface = { ...auth, customer: customer };
			this.storage.setItem('auth', authNew);
		} else {
			this.logout('expired');
		}
	}
}
