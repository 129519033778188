import { decrypt, encrypt } from '../util/endecrypt';
import { environment } from './../../environments/environment';
export abstract class StorageImpService implements Storage {
	constructor(protected readonly api: Storage) {}

	get length(): number {
		return this.api.length;
	}

	setItem(key: string, value: unknown): void {
		let data = JSON.stringify(value);
		// if (environment.production) {
		// 	data = encrypt(data, environment.keyEncryptStorage);
		// }
		this.api.setItem(key, data);
	}

	getItem<T>(key: string): T | null {
		const data = this.api.getItem(key);

		if (data !== null) {
			// if (environment.production) {
			// 	return decrypt<T>(data, environment.keyEncryptStorage);
			// }

			return JSON.parse(data) as T;
		}

		return null;
	}

	clear(): void {
		this.api.clear();
	}

	key(index: number): string | null {
		return this.api.key(index);
	}
	removeItem(key: string): void {
		this.api.removeItem(key);
	}
}