export const environment = {
    production: false,

    apiUrl: 'https://alianzaseguros.solunes.site/',
    apiCardPayment: 'https://payments.test.solunes.com/',
    // apiUrl: 'https://todotix.alianza.com.bo/',
    organizationCardPayment: '3aa9fda9-c252-47a5-90bb-de623c199a11',
    title: 'Alianza Seguros',
    subtitle: 'Alianza Seguros',
    description: `"Alianza Seguros" es un sitio en el que puedes administrar tus pagos.`,
    keywords: 'alianza, Alianza, seguros',
    yearProyect: '2024',

    imgLogo: '/projects/alianza/img/logo.png',
    imgLogoSVG: '/projects/alianza/img/logo.svg',

    loaderType: 1,
    loaderDefaultText: 'Cargando...',

    authRedirect: '/pending-payments',

    keyEncrypt: 'ZPNPigWvDuqvTjvqtQDb5CDUM7FTbPTj',
    ivEncrypt: 'hP7TeIeXBZHSoYQi',

    keyEncryptStorage: 'CapYei@8',
};
