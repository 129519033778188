import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SettingsProvider {

  static api_url = environment.apiUrl;
  static api_payment_url = environment.apiCardPayment;
  static organization_code = environment.organizationCardPayment;

  private static endpoints = {
    login: 'api/login',
    register: 'api/auth/sign-up',
    logout: 'api/logout',
    account: 'api/auth/me',
  };


  constructor() {}

  static authRegister(): string{
    return this.api_url + this.endpoints.register;
  }

  static authLogin(): string{
    return this.api_url + this.endpoints.login;
  }

  static authLogout(): string{
    return this.api_url + this.endpoints.logout;
  }

  static getAccount(): string{
    return this.api_url + this.endpoints.account;
  }

  static getUrl(url:string): string{
    return this.api_url + 'api/' + url;
  }
  
  static url(url:string): string{
    return this.api_url + url;
  }
  
  static getCustomUrl(url:string): string{
    return url;
  }

  static getCardPaymentUrl(url:string): string{
    return this.api_payment_url + url;
  }

}
